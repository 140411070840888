import React, { Component }  from 'react';
import '../helpers/suppress-install-prompt';
import Layout from "../components/Layout";
import Seo from "../components/seo";
import AppBar from "../components/PyramidEn/AppBar";
import Hero from "../components/PyramidEn/Hero";
import Pyramid1 from "../components/PyramidEn/Pyramid1";
import Pyramid2 from "../components/PyramidEn/Pyramid2";
import Pyramid3 from "../components/PyramidEn/Pyramid3";
import Pyramid4 from "../components/PyramidEn/Pyramid4";
import Pyramid5 from "../components/PyramidEn/Pyramid5";
import Pyramid6 from "../components/PyramidEn/Pyramid6";
import Pyramid7 from "../components/PyramidEn/Pyramid7";
import Footer from "../components/PyramidEn/Footer";
import 'url-search-params-polyfill';
import { graphql } from "gatsby";

class Page extends Component {
    constructor(props) {
        super(props);
        this.state = { url: ''};
    }

    async componentDidMount(){
        this.buildURLfromQueryString();
    }

    buildURLfromQueryString(){
        const urlParams = new URLSearchParams(global.location.search);    
        const utm_campaign = urlParams.has('utm_campaign') ? `campaign=${encodeURIComponent(urlParams.get('utm_campaign'))}` : "";
        const utm_term = urlParams.has('utm_term') ? `&adgroup=${encodeURIComponent(urlParams.get('utm_term'))}` : "";
        const install_callback = urlParams.has('install_callback') ? `&install_callback=${encodeURIComponent(urlParams.get('install_callback'))}` : "";
        const click_callback = urlParams.has('click_callback') ? `&click_callback=${encodeURIComponent(urlParams.get('click_callback'))}` : "";
        const event_callback_fn1saf = urlParams.has('event_callback_fn1saf') ? `&event_callback_fn1saf=${encodeURIComponent(urlParams.get('event_callback_fn1saf'))}` : "";
        
        const PYRAMID_RICH_URL = `https://app.adjust.com/jp0rrqi?${utm_campaign}${utm_term}${install_callback}${click_callback}${event_callback_fn1saf}`;

        this.setState({url: PYRAMID_RICH_URL});
    }

    render(){
        return (
            <Layout
                page="pyramid"
                showMenu={false}
                showFooter={false}
                showExitup={false}
            >
                <Seo
                    title="Pyramid"
                >
                    <meta name="robots" content="noindex,nofollow" />
                </Seo>
                <AppBar
                    url={this.state.url}
                />
                <Hero hero={this.props.data.hero.childImageSharp.gatsbyImageData} />
                <Pyramid1 url={this.state.url} />
                <Pyramid2 />
                <Pyramid3 />
                <Pyramid4 url={this.state.url} />
                <Pyramid5 />
                <Pyramid6 url={this.state.url} />
                <Pyramid7
                    phones={this.props.data.phones.childImageSharp.gatsbyImageData}
                    url={this.state.url}
                />
                <Footer />
            </Layout>
        );    
    }
}

export default Page;

export const query = graphql`
    query {

        signet: file(relativePath: { eq: "icons/sygnet.png" }) {
            ...fixedImage62
        }

        hero: file(relativePath: { eq: "pyramid/hero.jpg" }) {
            ...fluidImage960
        }

        phones: file(relativePath: { eq: "pyramid/phones-en.png" }) {
            ...fluidImage960
        }
    }
`;
