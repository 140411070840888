import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { GatsbyImage } from "gatsby-plugin-image";

const Pyramid7 = (props) => (
    <section className="pyramid-7">
        <Container>
            <Row className="align-items-center">
                <Col xs={{size: 12, order: 2}} md={{size: 5, offset: 1, order: 1}}>
                    <GatsbyImage image={props.phones} />
                </Col>
                <Col xs={{size: 12, order: 1}} md={{size: 6, order: 2}} className="text-center grey-bg">
                    <h2>Ready for the Most Incredible 12-Minute Workout of Your Life?</h2>
                    <p>Join 1,000,000+ action takers burning 1000s of calories and getting their dream body with Pyramid training. Download the Diet & Training by Ann app for FREE today!</p>
                    <a href={props.url} className="btn btn-primary">Download free now</a>
                    <h2 className="mt-5">Your One-on-One Trainer Anna Lewandowska – Europe’s Online Workout Queen</h2>
                    <p>2.5m+ followers, European and World Championship karate medalist & wife of footballer Robert Lewandowski, ranked among the world’s best strikers</p>
                </Col>
            </Row>
        </Container>
    </section>
);
    
export default Pyramid7;
